import React from "react";

export const SoundCloudButton = (): JSX.Element => {
  return (
    <p className="button" style={{ marginBottom: "5px" }}>
      <a
        href="https://soundcloud.com/bckspcmedia"
        target="_blank"
        rel="noreferrer"
      >
        SoundCloud Page
      </a>
    </p>
  );
};

export const YoutubeButton = (): JSX.Element => {
  return (
    <p className="button" style={{ marginBottom: "5px" }}>
      <a
        href="https://www.youtube.com/channel/UCfV-QwkNccZ0M368JSu8LPw"
        target="_blank"
        rel="noreferrer"
      >
        YouTube Channel
      </a>
    </p>
  );
};
