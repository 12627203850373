import React, { ReactElement } from "react";
import TrackEmbed, { TrackEmbedProps } from "../components/TrackEmbed";

const Listen = (): ReactElement => {
  const trackProps: TrackEmbedProps[] = [
    { title: "Gleam", id: "1873539969" },
    { title: "Lucky", id: "1805121957" },
    { title: "Hum", id: "1809285228" },
    // { title: "bios", id: "1606359522" },
  ];

  return (
    <div className="page-container">
      {/* <SoundCloudButton /> */}
      <div className="media-list-flex">
        {trackProps.map((props: TrackEmbedProps) => (
          <TrackEmbed key={props.title} title={props.title} id={props.id} />
        ))}
      </div>
    </div>
  );
};

export default Listen;
