import React, { ReactElement } from "react";
import Listen from "./Listen";
import Watch from "./Watch";
import {
  SoundCloudButton,
  YoutubeButton,
} from "../components/ExternalLinkButtons";

const Home = (): ReactElement => {
  return (
    <div className="page-container">
      <Listen />
      <Watch />
      <div className="button-container">
        <SoundCloudButton />
        <YoutubeButton />
      </div>
    </div>
  );
};

// const MateriaPlayer = (): ReactElement => (
//   <div
//     data-aos="zoom-out"
//     className="media-container"
//     style={{ width: "50vw" }}
//   >
//     <iframe
//       width="100%"
//       height="450"
//       // allow="autoplay"
//       src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1618706209&color=%23342c34&auto_play=true&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
//     ></iframe>
//     <div
//       style={{
//         fontSize: "10px",
//         color: "#cccccc",
//         lineBreak: "anywhere",
//         wordBreak: "normal",
//         overflow: "hidden",
//         whiteSpace: "nowrap",
//         textOverflow: "ellipsis",
//         fontFamily:
//           "Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif",
//         fontWeight: 100,
//       }}
//     >
//       <a
//         href="https://soundcloud.com/bckspcmedia"
//         title="bckspc"
//         style={{
//           color: "#cccccc",
//           textDecoration: "none",
//         }}
//       >
//         bckspc
//       </a>{" "}
//       ·{" "}
//       <a
//         href="https://soundcloud.com/bckspcmedia/sets/materia"
//         title="materia"
//         style={{
//           color: "#cccccc",
//           textDecoration: "none",
//         }}
//       >
//         materia
//       </a>
//     </div>
//   </div>
// );

// const Header = (): ReactElement => (
//   <p
//     style={{
//       textAlign: "center",
//       margin: "20px",
//       fontSize: "28px",
//       color: "white",
//     }}
//   >
//     MATERIA EP: OUT NOW
//   </p>
// );

export default Home;
